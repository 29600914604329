import React from "react"
import { Link as ReachLink, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Box, Heading, Text } from "@chakra-ui/core"

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact us" description="Contact us" keywords="Contact us" />
      <Box>
        <Heading as="h1" mt={6} textAlign="center" lineHeight="2">
          Contact us
        </Heading>
        <Box h="75vh" display="flex" alignItems="center">
          <Box mt={2} id="index" color="gray.600" display={{ md: "flex" }}>
            <Box
              width={["100%", "100%", "50%"]}
              backgroundImage="url('/assets/alexander-andrews-HgUDpaGPTEA-unsplash.jpg')"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
            >
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                backgroundColor="rgba(0,0,0,.4)"
                p={[3, 3, 10]}
                h={{ md: "360px" }}
                alignContent="center"
                color="white"
                textAlign="center"
              >
                <Text fontSize="32px" fontWeight="bold" w="100%">
                  Get in Touch
                </Text>
                <Text fontSize="16px" w="100%">
                  Let's have a talk
                </Text>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              width={["100%", "100%", "50%"]}
              p={3}
            >
              <Text>
                If you have any question, please feel free to contact us by
                email. I will get back to you as soon as I can. You can send us
                an email at: wheyon{`{[at]}`}outlook.com
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default ContactPage
